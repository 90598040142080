// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abonnement-tsx": () => import("./../../../src/pages/abonnement.tsx" /* webpackChunkName: "component---src-pages-abonnement-tsx" */),
  "component---src-pages-bekreft-tsx": () => import("./../../../src/pages/bekreft.tsx" /* webpackChunkName: "component---src-pages-bekreft-tsx" */),
  "component---src-pages-bestill-tsx": () => import("./../../../src/pages/bestill.tsx" /* webpackChunkName: "component---src-pages-bestill-tsx" */),
  "component---src-pages-brukervilkar-if-pilot-tsx": () => import("./../../../src/pages/brukervilkar_ifPilot.tsx" /* webpackChunkName: "component---src-pages-brukervilkar-if-pilot-tsx" */),
  "component---src-pages-brukervilkar-tsx": () => import("./../../../src/pages/brukervilkar.tsx" /* webpackChunkName: "component---src-pages-brukervilkar-tsx" */),
  "component---src-pages-getandroid-tsx": () => import("./../../../src/pages/getandroid.tsx" /* webpackChunkName: "component---src-pages-getandroid-tsx" */),
  "component---src-pages-getios-tsx": () => import("./../../../src/pages/getios.tsx" /* webpackChunkName: "component---src-pages-getios-tsx" */),
  "component---src-pages-hjelp-tsx": () => import("./../../../src/pages/hjelp.tsx" /* webpackChunkName: "component---src-pages-hjelp-tsx" */),
  "component---src-pages-if-pilot-tsx": () => import("./../../../src/pages/if_pilot.tsx" /* webpackChunkName: "component---src-pages-if-pilot-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobb-tsx": () => import("./../../../src/pages/jobb.tsx" /* webpackChunkName: "component---src-pages-jobb-tsx" */),
  "component---src-pages-kjopsvilkar-tsx": () => import("./../../../src/pages/kjopsvilkar.tsx" /* webpackChunkName: "component---src-pages-kjopsvilkar-tsx" */),
  "component---src-pages-komigang-tsx": () => import("./../../../src/pages/komigang.tsx" /* webpackChunkName: "component---src-pages-komigang-tsx" */),
  "component---src-pages-loginlink-tsx": () => import("./../../../src/pages/loginlink.tsx" /* webpackChunkName: "component---src-pages-loginlink-tsx" */),
  "component---src-pages-nerdemodus-tsx": () => import("./../../../src/pages/nerdemodus.tsx" /* webpackChunkName: "component---src-pages-nerdemodus-tsx" */),
  "component---src-pages-oss-tsx": () => import("./../../../src/pages/oss.tsx" /* webpackChunkName: "component---src-pages-oss-tsx" */),
  "component---src-pages-personvern-tsx": () => import("./../../../src/pages/personvern.tsx" /* webpackChunkName: "component---src-pages-personvern-tsx" */),
  "component---src-pages-pilotkunde-tsx": () => import("./../../../src/pages/pilotkunde.tsx" /* webpackChunkName: "component---src-pages-pilotkunde-tsx" */),
  "component---src-pages-registrer-tsx": () => import("./../../../src/pages/registrer.tsx" /* webpackChunkName: "component---src-pages-registrer-tsx" */),
  "component---src-pages-reservasjonsvilkar-tsx": () => import("./../../../src/pages/reservasjonsvilkar.tsx" /* webpackChunkName: "component---src-pages-reservasjonsvilkar-tsx" */),
  "component---src-pages-reserver-tsx": () => import("./../../../src/pages/reserver.tsx" /* webpackChunkName: "component---src-pages-reserver-tsx" */),
  "component---src-pages-tjenester-tsx": () => import("./../../../src/pages/tjenester.tsx" /* webpackChunkName: "component---src-pages-tjenester-tsx" */)
}

